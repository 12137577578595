@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&family=Roboto:wght@400;500;700&display=swap);
/* Google Fonts */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

body {
  background: #000;
  font-family: "Roboto", sans-serif;
}

a {
  color: #65ed26;
}

.Mint {
  min-height: 100vh;
  margin: -150px auto 0;
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Mint_Con {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}

.Mint h1 {
  color: #fff;
  font-weight: 400;
}

.Mint h2 {
  color: #fff;
  font-weight: 400;
  font-size: 6em;
  text-shadow: 1px 1px 12px #030706;
  line-height: 1em;
  margin-top: 35px;
}

.Mint h3 {
  color: #fff;
  margin-top: 10px;
  font-weight: 400;
  font-size: 2em;
  text-shadow: 1px 1px 12px #030706;
}

.Mint button {
  border: 4px #2f750f solid;
  padding: 15px 25px;
  color: #000;
  border-radius: 40px;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  font-weight: 600;
  background-color: #65ed26;
  margin-top: 35px;
  cursor: pointer;
  transition: 0.3s;
}

.Mint button:hover {
  color: #65ed26;
  border: 4px #65ed26 solid;
  background-color: #000;
}

.Mint button.mintbtn {
  border-radius: 0;
}

.Mint button.mintbtn-decrease {
  border-radius: 30px 0 0 30px;
}

.Mint button.mintbtn-increase {
  border-radius: 0 30px 30px 0;
}

.Wallet {
  min-height: 100vh;
  display: flex;
  background-color: #1c2b28;
  align-items: center;
  padding: 50px 0;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Wallet h1 {
  font-size: 52px;
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin: 35px 0;
}

.Wallet h2 {
  color: #fff;
  font-size: 22px;
  margin: 0;
  font-weight: 400;
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
}
.Wallet h2.wallet_address {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 30px;
  color: #ccc;
  margin: 0 auto 50px;
}

.Wallet p {
  color: #77858c;
  margin: 0;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 400;
  -webkit-line-clamp: 1;
}

.Wallet video {
  object-fit: contain;
  object-position: top;
  border-radius: 10px;
  aspect-ratio: 1;
  background: #000;
  width: 100%;
}

.Wallet button {
  height: 55px;
  border: 4px #2f750f solid;
  padding: 0 25px;
  color: #000;
  border-radius: 30px;
  align-self: center;
  font-family: "Oswald", sans-serif;
  font-size: 21px;
  font-weight: 600;
  background-color: #65ed26;
  margin-top: 20px;
  cursor: pointer;
}

.Wallet button:hover {
  color: #65ed26;
  border: 4px #65ed26 solid;
  background-color: #000;
}

.Wallet .extra-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
  gap: 5px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.Wallet .extra-navigation a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  font-size: 22px;
  cursor: not-allowed;
}

.Wallet .extra-navigation a:first-child {
  border-radius: 12px 0 0 12px;
}

.Wallet .extra-navigation a:last-child {
  border-radius: 0 12px 12px 0;
}

.Wallet .extra-navigation a:hover {
  background-color: #000;
  color: #65ed26;
}

.Wallet .extra-navigation img {
  max-width: 50px;
  margin: 5px 20px 5px 0;
}

.Wallet .extra-navigation svg {
  fill: white;
}

.Wallet_Con {
  height: 100%;
  width: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.Wallet_Pic_Con {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  margin: 15px auto 35px;
}
.Wallet_Pic_Con img {
  margin: 0 35px;
  margin-top: 35px;
  object-fit: contain;
  object-position: top;
  border-radius: 10px;
  aspect-ratio: 1;
}

.Wallet_Pic {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  border-radius: 12px;
  border: 5px #1c2b28 solid;
  background-color: #1c2b28;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.Modal_Con {
  background-color: #fff;
  height: auto;
  padding: 20px;
  display: flex;
  position: relative;
  width: 800px;
  border-radius: 10px;
  align-items: center;
}

.Modal img {
  height: 400px;
  width: 400px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.Modal video {
  height: 400px;
  width: 400px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #f7f7f7;
}

.Modal h1 {
  text-align: left;
  font-family: "Oswald", sans-serif;
}

.Modal p {
  text-align: left;
}

.Modal h3 {
  font-size: 13px;
  margin: 0px;
  color: #fff;
}

.Modal_Right {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 48%;
  margin-left: 2%;
}
.Modal_Right h2 {
  margin-top: 15px;
  font-family: "Oswald", sans-serif;
}

.Modal_Atr {
  width: 100%;
}

.Show {
  display: flex;
}

.Modal_Close {
  height: 20px;
  width: 20px;
  border-radius: 10px;
  cursor: pointer;
  right: 10px;
  top: 10px;
  background-color: #000;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
}
.Modal_Close:hover {
  background-color: #65ed26;
}

.disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

@media only screen and (max-width: 1200px) {
  .Wallet_Pic_Con {
    grid-template-columns: auto auto;
    justify-content: space-evenly;
  }
}

@media only screen and (max-width: 992px) {
  .Wallet_Pic_Con {
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
  }
  .Wallet_Con {
    align-items: center;
  }
  .Wallet h1 {
    margin-left: 0px;
    text-align: center;
  }
  .Modal_Con {
    width: 80%;
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
  }
  .Modal img {
    height: 250px;
    width: 100%;
  }
  .Modal_Right {
    width: 100%;
  }
  .Modal h1 {
    font-size: 25px;
  }
  .Modal h2 {
    font-size: 20px;
  }
  .Modal p {
    font-size: 14px;
  }
  .Wallet .extra-navigation {
    grid-template-columns: repeat(2, 1fr);
  }

  .Wallet .extra-navigation a {
    flex-direction: column;
    border-radius: 12px !important;
  }
}

